import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../Styles/Nav.scss';
import Logo from '../Assets/Images/nbf.jpg'
import * as ROUTES from '../Constants/routes'
import useAuthListener from '../Hooks/use-auth-listener'
import { Button } from 'react-bootstrap';

function Navbar() {
  const [click, setClick] = useState(false);
  const { user } = useAuthListener();
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <>
      <nav className='navbar'>
        <Link to={ROUTES.HOME} className='navbar-logo' onClick={closeMobileMenu}>
          <img src = {Logo} alt ="" className ="logo"></img>
        </Link>
        <div className='menu-icon' onClick={handleClick}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <li className='nav-item'>
            <Link to='/' className='nav-links' onClick={closeMobileMenu}>
              Home
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/about-us'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              About Us
            </Link>
                  </li>
           <li className='nav-item'>
            <Link
              to='/gallery'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              Gallery
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to='/contact-us'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              Contact Us
            </Link>
          </li> 
          
            <Link 
              to={(user) ? '/signout':'/signin' }
              className ="navText"
              onClick={closeMobileMenu}
            ><button className='navButton'>
              {(user) ? 'Sign Out' : 'Sign In'}
              </button>
            </Link>
         
          
        </ul>
        
      </nav>
    </>
  );
}
export default Navbar;