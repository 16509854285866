import React from 'react'
import { Row, Col } from 'react-bootstrap'
import '../Styles/About.scss'

function infoContainer() {
    return (
        <div className ="infoContainer">
             <div className="optionsAbout">
                        <Row className ="rowWrapper">
                        <Col sm={7}>
                        <div className ="option">
                            <h3>For Club Owners</h3>
                            <p>You can use this platform to find the talent you need for upcoming standup events in your clubs. You can connect with the talent and start talking business or put up an ad on the platform and invite comedians to contact you.</p>
                                </div>
                            </Col>
                            <Col sm={5}>  
                        <div className ="option">
                            <h3>For Comedy Artists</h3>
                            <p>You can connect with the club owners in your preferred cities and localities and share your art with them. You will also find useful materials including strategies to step up your game and build your standup career.</p>
                        </div>
                            </Col>
                            </Row>
                    </div>
        </div>
    )
}

export default infoContainer
