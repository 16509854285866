import Firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth'
import { seedDatabase } from '../seed';

//Seed the database

//Config here
const config = {
    apiKey: "AIzaSyByMesa0bIZpC2wrP3757tMVPKUlZgdA6U",
    authDomain: "naturalbornfool-1b7fa.firebaseapp.com",
    projectId: "naturalbornfool-1b7fa",
    storageBucket: "naturalbornfool-1b7fa.appspot.com",
    messagingSenderId: "701528720850",
    appId: "1:701528720850:web:766ce7015c4f2a5926340b",
    measurementId: "G-3MCF7TYP03"
}

// Production Config
// apiKey: "AIzaSyByMesa0bIZpC2wrP3757tMVPKUlZgdA6U",
//     authDomain: "naturalbornfool-1b7fa.firebaseapp.com",
//     projectId: "naturalbornfool-1b7fa",
//     storageBucket: "naturalbornfool-1b7fa.appspot.com",
//     messagingSenderId: "701528720850",
//     appId: "1:701528720850:web:766ce7015c4f2a5926340b",
//     measurementId: "G-3MCF7TYP03"

const firebase = Firebase.initializeApp(config)

export { firebase };