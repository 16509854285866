import React, {useState, useContext} from 'react'
import '../Styles/Signup.scss'
import { makeStyles } from '@material-ui/core/styles';
import PhoneIcon from '@material-ui/icons/Phone';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import EmailIcon from '@material-ui/icons/Email';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import Logo from '../Assets/Images/nbf.jpg'
import { Link } from "react-router-dom"
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { FirebaseContext } from '../Context/firebase'
import * as ROUTES from '../Constants/routes';
import { useHistory } from 'react-router-dom';
import Recaptcha from 'react-recaptcha';
import emailjs from 'emailjs-com';  

const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },
}));


export default function Signup() {
    const history = useHistory();
    const { firebase } = useContext(FirebaseContext);
    const [checked, setChecked] = React.useState(false);
    const classes = useStyles();
    const [user, setUser] = useState('');
    const [email, setEmail] = useState('');
    const [phone,setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [retypePassword, setretypePassword] = useState('');
    const [error, setError] = useState('');
    const [fullName, setFullName] = useState('');
    const [isVerified, setisVerified] = useState(false);
    const handleChange = (event) => {
        setChecked(event.target.checked);
    };
    
    const isInvalid = user === '' || phone === '' || password === '' || email === '' || retypePassword === '' || password !== retypePassword || checked !== true;
    
    function handleSignUp(e) {
        e.preventDefault();
            firebase
                .auth()
                .createUserWithEmailAndPassword(email, password)
                .then((result) => {
                    result.user
                        .updateProfile({
                            displayName: user,
                            phoneNumber: phone,
                        }).then(() => {
                            history.push(ROUTES.GALLERY)
                        })
                })
                .then(() => {
                    emailjs.sendForm('service_6a1w0fm', 'template_ujdwhln', e.target, 'user_DD5luoPlR6xCb4LSCWcKb')
                       .then((result) => {
                           console.log(result.text);
                       }, (error) => {
                           console.log(error.text);
                       });
                        e.target.reset() 

                })
                .catch((error) => {
                    setUser('')
                    setFullName('')
                    setPhone('')
                    setEmail('')
                    setPassword('')
                    setretypePassword('')
                    const message = "Account already exists. Please sign in"
                    setError(message);
                })


        }
    
   

    return (
        <div className = "signupContainer">
            <form className="formWrapper" onSubmit = {handleSignUp} method ="POST">
                <img src={Logo} alt="logo" className="logoForm"></img>
                <FormControl className={classes.margin}>
                    {error && <p data-testid="error">{error}</p>}   
                    <TextField
                    className={classes.margin}
                        id="input-with-icon-textfield"
                        type = "text"
                        name="user"
                        value={user}
                        onChange = {({target})=> setUser(target.value)}
                        placeholder ="User Name"
                        InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <PersonIcon  />
                                </InputAdornment>
                                
                    ),
                    }}
                    />
                    <TextField
                    className={classes.margin}
                        id="input-with-icon-textfield"
                        type = "text"
                        name="fullName"
                        value={fullName}
                        onChange = {({target})=> setFullName(target.value)}
                        placeholder ="Full Name"
                        InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <PersonIcon  />
                                </InputAdornment>
                                
                    ),
                    }}
                    />
                    <TextField
                    className={classes.margin}
                        id="input-with-icon-textfield"
                        type = "email"
                        name="email"
                        value={email}
                        onChange = {({target})=> setEmail(target.value)}
                        placeholder ="Email"
                        InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <EmailIcon  />
                                </InputAdornment>
                                
                    ),
                    }}
                    />
                    <TextField
                    className={classes.margin}
                        id="input-with-icon-textfield"
                        type ="tel"
                        name="phone"
                        value={phone}
                        onChange = {({target})=> setPhone(target.value)}
                        placeholder ="Phone Number"
                        InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <PhoneIcon  />
                                </InputAdornment>
                                
                    ),
                    }}
                    />
                    <TextField
                    className={classes.margin}
                        id="input-with-icon-textfield"
                        name="password"
                        type="password"
                        value={password}
                        onChange={({ target }) => setPassword(target.value)}
                        autoComplete="off"
                        placeholder ="Password"
                        InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <LockIcon  />
                                </InputAdornment>
                                
                    ),
                    }}
                    />
                    <TextField
                    className={classes.margin}
                        id="input-with-icon-textfield"
                        name ="retypePassword"
                        type ="password"
                        value={retypePassword}
                        autoComplete="off"
                        onChange={({ target }) => setretypePassword(target.value)}
                        placeholder ="Reenter Password"
                        InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <LockIcon  />
                                </InputAdornment>
                                
                    ),
                    }}
                    />
                    {password !== retypePassword ? <p>Passwords do not match</p>:''}
                    <Button className="loginBtn"
                        disabled={isInvalid}
                        type ="submit"
                    >Register</Button>
                    <p><Checkbox
                        checked={checked}
                        onChange={handleChange}
                        name ="terms"
                        color= "primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                    /> Agree to Terms and Conditons</p>

                    <p>Have an accout already?<Link to ="/signin"> Sign In</Link></p>
                </FormControl>

            </form> 
        </div>
    )
}