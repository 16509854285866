import Jumbotron  from '../Components/Jumbotron/Jumbotron'
import jumboData from '../Fixtures/jumbo.json';
import '../Styles/JumbatronContainer.scss'

export default function JumbotronContainer() {
  return (
    <section className = "paneContainer">
      
      <Jumbotron>
        {jumboData.map((item) => (
          <Jumbotron key={item.id} direction={item.direction}>
            <div className="pane1">
              <p className="jumboTitle">{item.title}</p>
              <p className="jumbosubtitle">{item.subTitle}</p>
            </div>
            <div className = "pane2">
            </div>
          </Jumbotron>
        ))}
        </Jumbotron>
      
    </section>
  );
}