import React, { useState, useEffect } from 'react'
import Iframe from 'react-iframe'
import ShowMore from '@tedconf/react-show-more';
import '../Styles/ContentContainer.scss';

export default function ContentRow({ museAiVideos }) {
    const [museAiVideoSvid, setMuseAiVideoSvid] = useState([]);

    const getMuseAiVideoSvid = (videosArray) => {
        const svid = videosArray.map(video => video.svid);
        setMuseAiVideoSvid(svid);
    }

    useEffect(() => {
        getMuseAiVideoSvid(museAiVideos);
    }, [museAiVideos]);
 
    return (
       
        <>  
            <section className="videosContainer pt-5">
                <div className="row">
                    <ShowMore
                        items={museAiVideos}
                        by={8}
                    >
                        {({
                        current,
                        onMore,
                        }) => (
                        <>
                            {current.map((video) => (
                                <>
                                    <div className="col-md-3 col-12 col-sm-2">
                                        <div className="p-3">
                                            <Iframe
                                                url={`https://muse.ai/embed/${video.svid}?search=0&links=0&logo=0`}
                                                key={video.fid}
                                                display="initial"
                                                width="100%" 
                                                height="100%" 
                                                frameBorder="0"
                                                allowFullScreen="true"
                                                webkitallowfullscreen="true"
                                                mozallowfullscreen="true"
                                                scrolling="no"
                                            />
                                        </div>
                                    </div> 
                                </>
                            ))}
                            <div className="d-flex mx-auto">
                                <button
                                    type="button"
                                    className="btn btn-primary justify-self-center"
                                    disabled={!onMore}
                                    onClick={() => { if (!!onMore) onMore(); }}
                                >
                                    LOAD MORE
                                </button>
                            </div>
                        </>
                        )}
                    </ShowMore>
                     
                </div>
            </section>
        </> 
    )
}