import React, {useContext, useState} from 'react';
import { FirebaseContext } from '../../Context/firebase'
import * as ROUTES from '../../Constants/routes';
import { useHistory } from 'react-router-dom';

export default function Signout() {
    const { firebase } = useContext(FirebaseContext);
    const history = useHistory();
    const [error, setError] = useState('')
    
    
    firebase.auth()
        .signOut()
        .then(() => {
            history.push(ROUTES.HOME);
        }).catch((error) => {
            const message = "You are not logged in"
            setError(message);
        });


    return (
        <>
         {error && <p data-testid="error">{error}</p>}
        </>
        
    )
}