import React from "react";

export default function Privacy() {
    return(
        <div className = "termsPrivacyContainer">
            <section className = "termsContainer">
                <h4>PRIVACY NOTICE</h4>
                <p>Last updated October 17, 2021</p>

                <p>Thank you for choosing to be part of our community at Invictus Interactive, LLC ("Company," "we," "us," or "our"). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy notice or our practices with regard to your personal information, please contact us at invictuspublish@gmail.com.
                This privacy notice describes how we might use your information if you:
                In this privacy notice, if we refer to:
                The purpose of this privacy notice is to explain to you in the clearest way possible what information we collect, how we use it, and what rights you have in relation to it. If there are any terms in this privacy notice that you do not agree with, please discontinue use of our Services immediately.
                Please read this privacy notice carefully, as it will help you understand what we do with the information that we collect.
                </p>

                <h4> WHAT INFORMATION DO WE COLLECT?</h4>
                <p>Personal information you disclose to us

                In Short:  We collect personal information that you provide to us.
                We collect personal information that you voluntarily provide to us when you register on the express an interest in obtaining information about us or our products and Services, when you participate in activities on the (such as by posting messages in our online forums or entering competitions, contests or giveaways) or otherwise when you contact us.
                The personal information that we collect depends on the context of your interactions with us and the , the choices you make and the products and features you use. The personal information we collect may include the following:
                Personal Information Provided by You. We collect names; email addresses; mailing addresses; usernames; passwords; and other similar information.
                Social Media Login Data. We may provide you with the option to register with us using your existing social media account details, like your Facebook, Twitter or other social media account. If you choose to register in this way, we will collect the information described in the section called "HOW DO WE HANDLE YOUR SOCIAL LOGINS?" below.
                All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.</p>

                <h3>Information automatically collected</h3>
                <p>In Short:  Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our .
                We automatically collect certain information when you visit, use or navigate the . This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about how and when you use our and other technical information. This information is primarily needed to maintain the security and operation of our , and for our internal analytics and reporting purposes.
                Like many businesses, we also collect information through cookies and similar technologies.
                The information we collect includes:
                Log and Usage Data. Log and usage data is service-related, diagnostic, usage and performance information our servers automatically collect when you access or use our and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type and settings and information about your activity in the  (such as the date/time stamps associated with your usage, pages and files viewed, searches and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps') and hardware settings).
                Device Data. We collect device data such as information about your computer, phone, tablet or other device you use to access the . Depending on the device used, this device data may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model Internet service provider and/or mobile carrier, operating system and system configuration information.
                Location Data. We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and settings of the device you use to access the . For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. Note however, if you choose to opt out, you may not be able to use certain aspects of the Services.
                </p>
                <h3>HOW DO WE USE YOUR INFORMATION?</h3>
                <p>In Short:  We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.

                We use personal information collected via our for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.

                We use the information we collect or receive:
                To facilitate account creation and logon process. If you choose to link your account with us to a third-party account (such as your Google or Facebook account), we use the information you allowed us to collect from those third parties to facilitate account creation and logon process for the performance of the contract. See the section below headed "HOW DO WE HANDLE YOUR SOCIAL LOGINS?" for further information.
                To post testimonials. We post testimonials on our that may contain personal information. Prior to posting a testimonial, we will obtain your consent to use your name and the content of the testimonial. If you wish to update, or delete your testimonial, please contact us at invictuspublish@gmail.com and be sure to include your name, testimonial location, and contact information.
                Request feedback. We may use your information to request feedback and to contact you about your use of our .
                To enable user-to-user communications. We may use your information in order to enable user-to-user communications with each user's consent.
                To manage user accounts. We may use your information for the purposes of managing our account and keeping it in working order.
                To send administrative information to you. We may use your personal information to send you product, service and new feature information and/or information about changes to our terms, conditions, and policies.
                To protect our Services. We may use your information as part of our efforts to keep our safe and secure (for example, for fraud monitoring and prevention).
                To enforce our terms, conditions and policies for business purposes, to comply with legal and regulatory requirements or in connection with our contract.
                To respond to legal requests and prevent harm. If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.
                </p>

                <h4>WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h4>
                <p>In Short:  We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.

                We may process or share your data that we hold based on the following legal basis:
                Consent: We may process your data if you have given us specific consent to use your personal information for a specific purpose.
                Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests.
                Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.
                Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).
                Vital Interests: We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.
                More specifically, we may need to process your data or share your personal information in the following situations:
                Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
                Google Maps Platform APIs. We may share your information with certain Google Maps Platform APIs (e.g., Google Maps API, Place API). To find out more about Google’s Privacy Policy, please refer to this link. We obtain and store on your device ('cache') your location. You may revoke your consent anytime by contacting us at the contact details provided at the end of this document.
                Vendors, Consultants and Other Third-Party Service Providers. We may share your data with third-party vendors, service providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that work. Examples include: payment processing, data analysis, email delivery, hosting services, customer service and marketing efforts. We may allow selected third parties to use tracking technology on the , which will enable them to collect data on our behalf about how you interact with our over time. This information may be used to, among other things, analyze and track data, determine the popularity of certain content, pages or features, and better understand online activity. Unless described in this notice, we do not share, sell, rent or trade any of your information with third parties for their promotional purposes.
                Third-Party Advertisers. We may use third-party advertising companies to serve ads when you visit or use the . These companies may use information about your visits to our Website(s) and other websites that are contained in web cookies and other tracking technologies in order to provide advertisements about goods and services of interest to you.
                </p>

                <h4>WHO WILL YOUR INFORMATION BE SHARED WITH</h4>
                <p>In Short:  We only share information with the following third parties.
                We only share and disclose your information with the following third parties. If we have processed your data based on your consent and you wish to revoke your consent, please contact us using the contact details provided in the section below titled "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?".</p>

                <h4>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h4>
                <p>In Short:  We may use cookies and other tracking technologies to collect and store your information.
                We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.</p>


            </section>
        </div>
    );
}  