import React from 'react'
import '../Styles/VideosContainer.scss'
import ContentRow from './ContentRow.jsx';


export default function VideoContainer({ museAiVideos }) {
    return (
        <>
         <ContentRow comedian museAiVideos={museAiVideos} />

        </>
    )
}