import React from 'react';
import '../Styles/MoveReel.scss'
import Featured from '../Containers/Featured'
import { Link } from 'react-router-dom';

export default function Movie() {
    return (
        <div className ="movieReelContainer">
          <h4 className = "featuredTitle">Featured Comedians</h4>
            <Featured />
        <div className ="galleryButton">  
                <Link to ="/gallery">
                    <button>Enter Gallery</button> 
                    </Link>
        </div>
        </div>
    )

}