import React from 'react'
import '../Styles/infoContainer.scss'
import Logo from '../Assets/Images/nbf.jpg'

import { Link } from 'react-router-dom';

export default function About() {
    return (
        <div>
            <section className="aboutContainer">
                
                <div className="headingWrapper">
                <img src ={Logo} alt='logo'/>
                <h2>You Must Be Joking…</h2>
                <h5>… Or this place is not for you.</h5>
                    <p>We’re dead serious. Natural Born Fool is for the bravest of us: standup comedians. People who love to make others laugh and uncomfortable at the same time. If that sounds like you, and you have been looking for a professional social platform where you could meet other standup artists and club owners, we are at your service. </p>  
                    <div className = "aboutBox">
                        <p className="boxFont">FOR THE LOVE OF ALL THAT'S FUNNY!</p>
                    </div>
                    <p className ="paraAbout">The noble art of standup comedy has always been about owning the microphone in front of a live audience. When we say owning it, we mean using it to share stories about a variety of ideas in an unconventional, funny way that cracks up the crowd and leaves them with food for thought. </p>
                   
                    <p className ="paraAbout">Standup comedy is the purest form of wit and courage. And that’s what Natural Born Fool celebrates! This professional and comedy events platform is where emerging standup comedians and club owners connect to create events full of authentic comedy and endless laughter.</p>
                    
                     <div className ="bottomWrapper">
                        <div>
                        <h3>Our Vision</h3>
                        <p>Our vision at NBF is to offer our subscribers and sponsors quality services that deliver a joyful experience without exception, using cutting edge technology and a combination of traditional and innovative performance settings.</p>
                        </div>
                        <div>
                        <h3>Our Mission</h3>
                        <p>Our singular mission is to be as fearless as an 18-year old while employing the professionalism of a 40-year old, embracing the evolving nuance of web publishing and an unflinching philosophy of hard work.</p>  
                        </div>
                        <div className="valuesContainer">
                            <h3>Our Values</h3>   
                            <ul>
                            <li><b>BE FUNNY:</b> Above all else, we are funny. We use it as a means to better connect with others and build new opportunities for our artists.
                            </li>
                            <li><b>BE FEARLESS:</b> We believe fear is the biggest roadblock in the entertainment business. We are fearless and meet challenges head on, something we inculcate into the young talent we promote.
                            </li>
                            <li><b>BE READY:</b> Success happens when you are prepared for the opportunities coming your way. We are forever ready for the road ahead and so are the artists we represent.

                            </li>
                            <li><b>BE YOU:</b> Having a unique voice is crucial for success and growth. We are a unique team, creating opportunities for comedians with a unique voice.

                            </li>
                            </ul>    
                        </div>
                        <Link to = "/signup">
                            <button className="loginBtn">Sign Up</button>
                        </Link>
                    </div>
                
                
                
                
                
                </div>
                </section>
        </div>
    )
}