import React from 'react';
import Movie from '../Containers/Movie';
import JumbotronContainer from '../Containers/Jumbotron'
import FootersContainer from '../Containers/Footer'
import HeaderContainer from '../Containers/Header'
import InfoContainer from '../Containers/infoContainer'

export default function Home() {
    return (
        <div>
            <HeaderContainer />
            <InfoContainer/>
            <JumbotronContainer />
            <Movie />
            <FootersContainer/>
        </div>
    )
}