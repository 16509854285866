import React, {useState, useContext} from 'react'
import '../Styles/Signin.scss'
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import PersonIcon from '@material-ui/icons/Person';
import LockIcon from '@material-ui/icons/Lock';
import Logo from '../Assets/Images/nbf.jpg'
import { Link } from "react-router-dom"
import Button from '@material-ui/core/Button';
import { FirebaseContext } from '../Context/firebase'
import * as ROUTES from '../Constants/routes';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },
}));

export default function Signin() {
    const { firebase } = useContext(FirebaseContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const classes = useStyles();
    const history = useHistory();
    

    const isInvalid = password === '' || email === '';
   
    //check if form inputs are valid

    function handleSignOn(e){
        e.preventDefault();
        firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(() => {
                history.push(ROUTES.GALLERY);
            })
            .catch((error) => {
                setEmail('');
                setPassword('');
                const message = "The email or password is incorrect"
                setError(message);
        })
    }

    return (
        <div className = "signinContainer">
            <form className="formWrapper" onSubmit={handleSignOn} method="POST">
                <img src={Logo} alt="logo" className="logoForm"></img>
                <FormControl className={classes.margin}>
                {error && <p data-testid="error">{error}</p>}
                    <TextField
                        className={classes.margin}
                        type="text"
                        id="input-with-icon-textfield"
                        name="email"
                        value={email}
                        onChange = {({target}) => setEmail(target.value)} 
                        placeholder ="Email" 
                        InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <PersonIcon />
                        </InputAdornment>
                    ),
                    }}
                    />
                    <TextField
                    className={classes.margin}
                        type ="password"
                        id="input-with-icon-textfield"
                        name="password"
                        autoComplete="off"
                        value={password}
                        onChange ={({target})=> setPassword(target.value)}
                        placeholder ="Password"
                        InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <LockIcon  />
                                </InputAdornment>
                                
                    ),
                    }}
                    />
                    <Link to = "/reset">Forgot password</Link>
                    <Button className="loginBtn"
                        disabled={isInvalid}
                        type ="submit">Login</Button>
                    <dir><p><Link to ="/terms">Terms and Conditions</Link></p><p><Link to ="/privacy">Privacy Policy</Link></p>
                    </dir>    
                    <p>Need to Register?<Link to ="/signup"> Register</Link></p>
                </FormControl>

            </form> 
        </div>
    )
}