import React,{useState, useEffect} from 'react'
import '../Styles/Featured.scss'
import { Swiper, SwiperSlide } from 'swiper/react';
import axios from 'axios';
//fixing
export default function Featured() {
    const [state, setState] = useState([]);
    const [responce, setResponce] = useState([]);
    const [links, setlinks] = useState([]);
    const proxyurl = "https://cors-anywhere.herokuapp.com/";

    function fetchName() {
        axios.get('https://laughing-raman-d0ae22.netlify.app/.netlify/functions/videosApi/', {
            headers: {
                'Access-Control-Allow-Origin': '*',
                }
        })
                .then(res => {
                console.log(res.data);
                const vedo = res.data.filter((item, index) => {
                    return item.name === "Featured Comedians";
                    
               })
               setResponce(vedo);
               
            })
        
              
          
            
        .catch(err => {
            console.log(err)
        })

       
        
    } 

    useEffect(() => {
    
        fetchName();
  
    }, [])    

    console.log(responce)
    // console.log(responce)
    // console.log(links)

    return (
        <section>
            
        <div className="featuredVideoContainer">
               {responce.map((item => (
                <>
                
                 <div className = "vidoesWrapper">
                    <Swiper
                    spaceBetween={50}
                    className="vidoesWrapper"
                    slidesPerView={4}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                    breakpoints={{
                        200: {
                            width: 200,
                            slidesPerView: 1,
                            spaceBetween: 50
                        },
                        600: {
                            width: 600,
                            slidesPerView: 3,
                            spaceBetween: 50
                        },
                        900: {
                            width: 900,
                            slidesPerView: 4,
                            spaceBetween: 50
                        },
                        1000: {
                            width: 1000,
                            slidesPerView: 4,
                            spaceBetween: 50
                          }
                    }}
               
                    >
                        
                        {item.videos.map((video, id) => (
                            <>
                            {console.log(item)}
                                {console.log(item.videos[id].svid)}
                                <SwiperSlide className ="video">
                                <iframe title={item}
                                key={id}
                                        src={`https://muse.ai/embed/${item.videos[id].svid}?search=0&links=0&logo=0`}
                                        width="100%" 
                                        height="100%" 
                                        frameBorder="0" allowFullScreen="true"
                                        webkitallowfullscreen="true"
                                        mozallowfullscreen="true"
                                        scrolling="no"
                                    ></iframe> 



                                </SwiperSlide>
                            </>
                            
                    ))}
                    
                        </Swiper>  
                    </div>    
                </>
                
            )))}
            </div>
            </section>
    )
}
