import 'react-app-polyfill/ie11';
import React from 'react';
import { render }from 'react-dom';
import './Styles/Default.scss';
import './Styles/BaseConfig.scss';
import App from './App';
import '@fortawesome/fontawesome-free/css/all.min.css'; import'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import { BrowserRouter as Router } from 'react-router-dom'
import { firebase } from './Lib/firebase.prod'
import {FirebaseContext} from './Context/firebase'


render(
  <FirebaseContext.Provider value = {{firebase}}>
    <Router>
      <App />
    </Router>
    </FirebaseContext.Provider>,
  document.getElementById('root')
);

