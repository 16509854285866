import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import BrowseContainer from '../Containers/Search'
import VideosContainer from '../Containers/Videos'

export default function Gallery() {
    const [museAiVideos, setMuseAiVideos] = useState([])

    useEffect(() => {
        fetchMuseAiVideos();
    }, [])

    const fetchMuseAiVideos = async() => {
        const response = await Axios({
            method: 'get',
            url: 'https://muse.ai/api/files/videos',
            headers: {
              "Content-Type": "application/json",
              "Key": process.env.REACT_APP_MUSEAI_API_KEY
            },
            data: {}
        })
        setMuseAiVideos(response.data)
    }
   
    // console.log(slides);
    // console.log('VIDEOS', museAiVideos);
    
    return (
        <div>
            <BrowseContainer />
            <VideosContainer museAiVideos={museAiVideos} />
        </div>
    )
        
}