import NavContainer from './Containers/Nav'
import * as ROUTES from './Constants/routes'
import { Switch, Route } from 'react-router-dom';
import Home from './Pages/Home'
import Signup from './Pages/Signup'
import Signin from './Pages/Signin'
import About from './Pages/About'
import Contact from './Pages/Contact'
import Gallery from './Pages/Gallery'
import Forgot from './Pages/ResetPass'
import Signout from './Components/Signout/Signout'
import './App.scss'
import { IsUserRedirect, ProtectedRoute } from './Helpers/routes'
import useAuthListener from './Hooks/use-auth-listener'
import Terms from './Pages/Terms';
import Privacy from './Pages/Privacy';


export default function App() {
  const { user } = useAuthListener();
  return (
    <div>
      <NavContainer />
      <Switch>
          <IsUserRedirect
            user={user}
            loggedInPath={ROUTES.HOME}
            path={ROUTES.SIGNIN}
            exact >
            <Signin/>
          </IsUserRedirect>
          <IsUserRedirect
            user={user}
            loggedInPath={ROUTES.HOME}
            path={ROUTES.SIGNUP}
            exact >
            <Signup />
        </IsUserRedirect>
        <IsUserRedirect
            user={user}
            loggedInPath={ROUTES.HOME}
            path={ROUTES.FORGOT}
            exact >
            <Forgot />
        </IsUserRedirect>
        <IsUserRedirect
            user={user}
            loggedInPath={ROUTES.HOME}
            path={ROUTES.PRIVACY}
            exact >
            <Privacy/>
        </IsUserRedirect>
        <IsUserRedirect
            user={user}
            loggedInPath={ROUTES.HOME}
            path={ROUTES.TERMS}
            exact >
            <Terms />
        </IsUserRedirect>
       
        <Route exact path ={ROUTES.HOME} component={Home}/>
        <Route exact path={ROUTES.ABOUTUS} component={About}/>
        <Route exact path={ROUTES.CONTACT} component ={Contact}/>
        <ProtectedRoute user={user}
          path={ROUTES.GALLERY} 
          exact>
        <Gallery/>
        </ProtectedRoute>
        <Route exact path ={ROUTES.SIGNOUT} component={Signout}/>
        </Switch>
      
    </div>
  );
}

