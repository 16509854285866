import React from 'react'
import '../Styles/FooterContainer.scss'
import Footer from '../Components/Footer/Footer'
import Navdata from '../Fixtures/nav.json'
import Footerdata from '../Fixtures/footer.json'
import Copyright from '../Components/Copyright/Copyright'
import { Row, Col, Container } from 'react-bootstrap';

export default function FootersContainer() {
    return (
        <div className="footersContainer">
           <Container>
           <Row className = "footersWrapper">
            
            <Footer className="footerWrapper" >
                {Navdata.map((item, id) => (
                    <Footer key={id}>
                        <Col sm={12}>
                        <p>{item.name}</p>
                        </Col>
                    </Footer>


                ))}
            </Footer>
            <Copyright className = "copyrightWrapper">
                {Footerdata.map((item, id) => (
                    <Copyright key={id} className="copyWrapper">
                      <Col sm={12}> 
                            <p><i className="fa fa-copyright" aria-hidden="true"> {item.year}</i></p><p>{item.name}</p>
                       </Col>
                    </Copyright>

                ))}
            </Copyright>
                    </Row>  
            </Container>      
        </div>
    )
}