import React, {useContext, useState} from 'react';
import { FirebaseContext } from '../Context/firebase'
import '../Styles/Browse.scss'
import { Form, Input, Row} from 'reactstrap';
//The gallery

export default function BrowseContainer({ slides }) {
    const { firebase } = useContext(FirebaseContext);
    const user = firebase.auth().currentUser || {};
    // const [searchTerm, setSearchTerm] = useState('');
    // const [searchActive, setSearchActive] = useState(false);
    
    return (
        <section className = "browserContainer">
         <h3>To book a comic visit our NBF App available in the app stores</h3>   
        </section>
    )
}