import React from 'react';
import Header from '../Components/Header/Header'
import '../Styles/HeaderContainer.scss';
import Google from '../Assets/Images/play.jpg'
import Apple from '../Assets/Images/apple.jpg'

function clickApple() {
    console.log("apple store")
}

function clickGoogle() {
    console.log("google store") 
}


export default function HeaderContainer() {
    return (
        <header className = "headerWrapper">
            <section className="boxWrapper">
            <h3>Download Now!</h3>
                <div className="storeWrapper">
                
                    <img src={Apple} alt='' className="play" onClick ={clickApple}></img>
                    <img src={Google} alt='' className="google" onClick ={clickGoogle}></img>
                </div>
                
            </section>

        </header>
       
    )
}