import React from 'react';
import './Styles/jumbotron.scss'

export default function Jumbotron({ children, direction = 'row', ...restProps }) {
    return (
        <section className = "">
        <div className="Inner">
            <div className="jumbotronContainer" {...restProps}>{children}</div> 
            </div>
        </section>
        
    )


}