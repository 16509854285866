import React, { useState} from 'react'
import '../Styles/Contact.scss'
import { Container, Row, Col } from 'react-bootstrap';
import { Form, FormGroup, Label, Input} from 'reactstrap';
import Logo from '../Assets/Images/nbf.jpg'
import emailjs from 'emailjs-com';
import Recaptcha from 'react-recaptcha';

export default function Contact() {
    
    const [isVerified, setisVerified] = useState(false);

    function sendEmail(e) {
        e.preventDefault();

        if (isVerified) {
            emailjs.sendForm('service_6a1w0fm', 'template_b6inzoz', e.target, 'user_DD5luoPlR6xCb4LSCWcKb')
                .then((result) => {
                    console.log(result.text);
                }, (error) => {
                    console.log(error.text);
                });
            e.target.reset();
        } else {
            alert('Verify if Human')
        }
    }
    function recaptchaLoaded() {
        console.log ('captchaloaded ')
    }

    function verifyCallback(response) {
        if (response) {
            setisVerified({
               isVerified:true
           })
       }
    }
        
    return (
        <Container className = "contactContainer">
            
                <Row >
                    <Col sm={5}>
                        <img src={Logo}
                            alt=""></img>

                    </Col>
                    <Col sm={7}>
                        
                    
                    <Form onSubmit={sendEmail}>
                        <Row form>
                            <Col md={12}>
                            <FormGroup>
                                <Label for="Full Name">Full Name</Label>
                                <Input type="text" 
                                        name="fullName"
                                        placeholder="Full Name" /> 
                            </FormGroup>
                            </Col>
                            <Col md={12}>
                            <FormGroup>
                                <Label for="email">Email</Label>
                                <Input type="email" 
                                        name="email"
                                        placeholder="Email" /> 
                            </FormGroup>
                            </Col>
                            <Col md={12}>
                            <FormGroup>
                            <Label for="message">Message</Label>
                                <Input type="textarea" 
                                name="message"
                                placeholder="Message" /> 
                                </FormGroup> 
                                <Row form>
                       
                                </Row>
                            <Recaptcha
                            sitekey="6LfN90kaAAAAANALi_8CPd7CrfJhv6u0LPGlc9e4"
                            render="explicit"
                            onloadCallback={recaptchaLoaded}
                            verifyCallback={verifyCallback}
                            />
                            </Col>  
                            <Col md={12}>
                                <button className="loginBtn">
                                    Submit
                                </button>
                            </Col>    
                        </Row>    

                    </Form>
                    </Col>

                </Row>
            

        </Container>
    )
}