import React, {useContext, useState} from 'react'
import '../Styles/Reset.scss'
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import EmailIcon from '@material-ui/icons/Email';
import Logo from '../Assets/Images/nbf.jpg'
import { Link } from "react-router-dom"
import Button from '@material-ui/core/Button';
import { FirebaseContext } from '../Context/firebase'
import * as ROUTES from '../Constants/routes';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },
}));



export default function Forgot() {
    const [email, setEmail] = useState('');
    const { firebase } = useContext(FirebaseContext);
    const isInvalid =  email === '';
    const history = useHistory();
    const [error, setError] = useState('');

    function handleSignOn(e) {
    console.log('pushed')
    e.preventDefault();
    firebase
    .auth()
    .sendPasswordResetEmail(email)
    .then(() => {
        history.push(ROUTES.SIGNIN);
    })
    .catch((error) => {
        setEmail('');
        const message = "The email is incorrect"
        setError(message);
})
    }


    const classes = useStyles();
    return (
        <div className = "signinContainer">
            
            <form className="formWrapper" onSubmit={handleSignOn} method="POST">
                <img src={Logo} alt="logo" className="logoForm"></img>
                <FormControl className={classes.margin}>
                {error && <p data-testid="error">{error}</p>}
                <TextField
                    className={classes.margin}
                        id="input-with-icon-textfield"
                        name="email"
                        value={email}
                        placeholder="Email Address"
                        onChange = {({target}) => setEmail(target.value)}
                        InputProps={{
                        startAdornment: (
                        <InputAdornment position="start">
                            <EmailIcon  />
                        </InputAdornment>
                    ),
                    }}
                    />
                    
                    <Button className = "loginBtn" 
                     disabled={isInvalid}
                     type ="submit">Reset</Button>
                    <p>Have an Account <Link to="/signin"> Sign In</Link></p>
                </FormControl>

            </form> 
        </div>
    )
}
